<template>
    <div class="hospitals">
    <PreLoader v-if="isLoading"></PreLoader>
        <v-card>
          <v-card-title>
          </v-card-title>
        <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
            <v-row>
              <v-col md="3" sm="3">
                <v-toolbar-title>Список студентов</v-toolbar-title>
              </v-col>
              <v-col md="4" sm="4">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Поиск..."
                solo
                dense
                hide-details
                ></v-text-field>
              </v-col>
              <v-col md="5" sm="5" align="end">
                <!--<v-btn color="#F53C56"  class="hospitals__addbtn mb-2 mr-5" @click="dialog=true">Добавить <v-icon>mdi-plus</v-icon></v-btn>
                <v-btn color="#40D6F2"  class="hospitals__btn mb-2 mr-5">Импорт</v-btn>
                <v-btn color="#7764E4" class="hospitals__btn mb-2 mr-5">Экспорт</v-btn>-->
              </v-col>
            </v-row>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
                v-if="!item.isActivated"
                md
                @click="activate(item)"
                color="green"
            >
                mdi-check-circle
            </v-icon>
            <v-icon
                v-if="item.isActivated"
                md
                @click="disActivate(item)"
                color="#F53C56"
            >
                mdi-close-circle
            </v-icon>
          </template>
        </v-data-table>
    </v-card>
    </div>
</template>
<script>
import PreLoader from '../../components/preloader/PreLoader.vue';
import * as firebase from '@/firebase';
export default {
    components: {
    PreLoader,
  },
  name: "Hospitals",
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'ФИО',
            align: 'start',
            value: 'title',
          },
          { text: 'Роль', value: 'role' },
          { text: 'Электронная почта', value: 'email' },
          { text: '', value: 'action',sortable: false },
          
          
        ],
        close: false,
        dialog2:false,
        locationId:0,
        isLoading:false,
        items:[],
        num:0
      }
    },
    created() {
      this.getTests();
    },           
    methods: {
        async getTests() {
          this.isLoading = true;
          this.events = await firebase.usersCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                  this.items.push({ ...el.data()})
              });
            })
            this.isLoading = false;
        },
        activate(item) {
            console.log(item)
            firebase.usersCollection.doc(item.id).get().then((snapshot) => {
                const data = snapshot.data();
                firebase.usersCollection.doc(snapshot.id).set({
                  ...data,
                  isActivated: true
                }).then(() => {
                    this.items = [];
                    this.getTests();
                    console.log('Successfully Activated!')
                })
            })
        },
        disActivate(item) {
            console.log(item)
            firebase.usersCollection.doc(item.id).get().then((snapshot) => {
                const data = snapshot.data();
                firebase.usersCollection.doc(snapshot.id).set({
                  ...data,
                  isActivated: false
                }).then(() => {
                    this.items = [];
                    this.getTests();
                    console.log('Successfully Disactivated!')
                })
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.hospitals{
  &__btn{
    color:#FFFFFF;
  }
  &__addbtn{
    color:#FFFFFF;
  }
}
.theme--light.v-btn {
    color: rgba(255, 255, 255, 0.87);
}
</style>